import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Container,
  TextField,
} from "@mui/material";
import "./popup.css";
import NoNotification from "../../assets/images/no-notifications.jpg";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  createNewConversation,
  getCategoriesList,
  getSubCategoriesList,
} from "../../api/Api";
import { SuccessIcon } from "../../assets/icons/icons";
import { IoArrowBack } from "react-icons/io5";
import { IoIosArrowRoundBack } from "react-icons/io";


let _dummy = [
  "Personal",
  "Family",
  "Fitness",
  "Jobs",
  "Tours",
  "Wellness",
  "Finance",
  "Shopping",
];

function CreatePop(props) {
  const { open, handleClose } = props;

  const [category, setCategory] = useState("");
  const [success, setSuccess] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [convoName, setConvoName] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categoryList, subCategoryList } = useSelector(
    (state) => state.category
  );

  function handlePopClose() {
    setCategory("");
    setSubCategory("");
    setConvoName("");
    setSuccess(false);
    handleClose();
  }

  const handleSubmit = async () => {
    if (convoName?.length === 0) {
        alert("Please enter conversation name")
    } else {
      setSuccess(true);
      const userEntry = {
        convo_name: convoName,
        category_id: category,
        sub_category_id: subCategory,
      };
      console.log(userEntry);
      dispatch(createNewConversation(userEntry, setSuccess));
    }

  };

  useEffect(() => {
    if (category !== "") {
      dispatch(getSubCategoriesList(category));
    }
  }, [category]);

  useEffect(() => {
    dispatch(getCategoriesList());
  }, []);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="create-pop"
      >
        {success ? (
          <Box className="success-popup">
            <IoIosCloseCircleOutline
              className="closeIcon"
              onClick={handlePopClose}
            />
            <SuccessIcon />
            <Typography variant="h5" className="title">
              Done!
            </Typography>
            <Typography className="message">
              Conversation({convoName}) created successfully
            </Typography>
          </Box>
        ) : (category === "" || page === 1) ? (
          <Box>
            <Box className="d-flex-between popup-header">
              <Typography fontSize={{xs:"17px",sm:"18px",md:"20px"}}>Select Category</Typography>
              <IoIosCloseCircleOutline onClick={handlePopClose} />
            </Box>
            <Box p={2} className="category-list">
              {categoryList &&
                categoryList.map((datas) => (
                  <Box
                    onClick={() => {
                      setCategory(datas._id)
                      setPage(2);
                    }}
                    className={`${
                      category === datas._id && "activeList"
                    } nameCard`}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/${datas.image}`}
                      alt={`icon for ${datas.name}`}
                      className="category-icon"
                    />
                    <Typography>{datas.name}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        ) : (subCategory === "" || page === 2) ? (
          <Box>
            <Box className="d-flex-between popup-header">
              <Typography fontSize={{xs:"17px",sm:"18px",md:"20px"}}> <IoIosArrowRoundBack onClick={()=>setPage(1)} /> Select Sub Category</Typography>
              <IoIosCloseCircleOutline onClick={handlePopClose} />
            </Box>
            <Box p={2} className="category-list">
              {subCategoryList &&
                subCategoryList.map((datas) => (
                  <Box
                    onClick={() => {
                      setSubCategory(datas._id)
                      setPage(3);
                    }}
                    className={`${
                      subCategory === datas._id && "activeList"
                    } nameCard`}
                  >
                    <img
                      src={`${process.env.REACT_APP_API_URL}/api/${datas.image}`}
                      alt={`icon for ${datas.name}`}
                      className="category-icon"
                    />
                    <Typography>{datas.name}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box className="d-flex-between popup-header">
              <Typography fontSize={{xs:"17px",sm:"18px",md:"20px"}}> <IoIosArrowRoundBack onClick={()=>setPage(2)} /> Create Conversation</Typography>
              <IoIosCloseCircleOutline onClick={handlePopClose} />
            </Box>
            <Box p={2}>
              <TextField
                fullWidth
                value={convoName}
                onChange={(e) => setConvoName(e.target.value)}
                className="convo-field"
                placeholder="Conversation Name"
                autoComplete="off"
              />
            </Box>
            <Box textAlign={"end"} p={2}>
              <Button className="btn-one" onClick={handleSubmit}>
                Create
              </Button>
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  );
}

export default CreatePop;
