import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./email.module.css";
import { IoMail, IoMenu } from "react-icons/io5";
import { errorState, toggleSidebar } from "../../redux/reducers/UserReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMyEmailMessages, getMyEmails } from "../../api/Api";
import EmailSubSideBar from "./EmailSubSideBar";
import EmailMessageSubSideBar from "./EmailMessageSubSidebar";

const EmailIndex = () => {
  const [isDrawer, setIsDrawer] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState([]);
  const [emailMessageList, setEmailMessageList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _token = JSON.parse(localStorage.getItem("userToken"));

  const handleGetEmails = async () => {
    let result = await getMyEmails();
    if (result?.status === 201 || result?.status === 200) {
      let all_notifications = result?.data?.data;
      setEmailsList(all_notifications);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  const handleGetEmailMessages = async () => {
    let result = await getMyEmailMessages(selectedEmail?._id);
    if (result?.status === 201 || result?.status === 200) {
      let all_notifications = result?.data?.data;
      setEmailMessageList(all_notifications);
    } else {
      dispatch(
        errorState(
          result?.data?.message ? result?.data?.message : result?.statusText
        )
      );
    }
  };

  useEffect(() => {
    if (_token && emailsList.length === 0) {
      handleGetEmails();
    }
  }, [_token]);

  useEffect(() => {
    console.log("Selected Email", selectedEmail);
    if (selectedEmail && emailMessageList.length === 0) {
      handleGetEmailMessages();
    }
  }, [selectedEmail]);

  return (
    <div className={classes.MainContainer}>
      <EmailSubSideBar
        emailsList={emailsList}
        setSelectedEmail={setSelectedEmail}
      />
      {selectedEmail && (
        <EmailMessageSubSideBar
          emailsList={emailMessageList}
          setSelectedEmail={setSelectedMessage}
        />
      )}
      <Box className={classes.content}>
        <Box>
          <Box className={classes.EmailHtmlContainer}>
          {selectedMessage && selectedMessage.htmlContent && (
              <div
                dangerouslySetInnerHTML={{ __html: selectedMessage.htmlContent }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default EmailIndex;
