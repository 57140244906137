import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import { IoMenu } from "react-icons/io5";
import classes from "./user.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../redux/reducers/UserReducer";
import { FiLogOut } from "react-icons/fi";
import { splitFirstLetter } from "../../utils/helper";
import ConfirmationPop from "../../popups/ConfirmationPop";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAlarm } from "react-icons/io";
import { MdEmail, MdOutlineMessage } from "react-icons/md";
import Logo2 from "../../assets/images/logo2.png";
import CreatePop from "../popup/CreatePop";

function Sidebar() {
  const location = useLocation();
  const { state } = location;
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [createPopup, setCreatePopup] = useState(false);
  const { sidebarToggle, convoInfo } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleConfirmLogout() {
    setConfirmPopup(false);
    localStorage.removeItem("userToken");
    navigate("/");
  }

  console.log("convoInfo =", convoInfo);

  return (
    <Box
      className={classes.sidebar_container}
      display={{ xs: "none", sm: "none", md: "block" }}
    >
      <Box className={classes.short_sidebar} mt={2}>
          <Box className={classes.sideBarMainContainer}>
            <img
              src={Logo2}
              alt="logo"
              className={classes.LogoStyles}
              style={{ cursor: "pointer" }} // Added pointer cursor for better UX
            />
            <Tooltip title="Chats" placement="right" arrow className="mt-4">
              <Typography
                textAlign={"center"}
                mb={2}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/user-page")}
              >
                <MdOutlineMessage style={{ fontSize: "25px" }} />
              </Typography>
            </Tooltip>
            <Tooltip title="Emails" placement="right" arrow className="mt-4">
              <Typography
                textAlign={"center"}
                mb={2}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/email")}
              >
                <MdEmail style={{ fontSize: "25px" }} />
              </Typography>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="Reminder" placement="right" arrow>
              <Typography
                textAlign={"center"}
                mb={2}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/reminder")}
              >
                <IoMdAlarm style={{ fontSize: "25px" }} />
              </Typography>
            </Tooltip>
            <Button
              className={classes.logout_btn}
              sx={{ padding: "10px 0" }}
              onClick={() => setConfirmPopup(true)}
            >
              <FiLogOut />
            </Button>
          </Box>
        </Box>
      <ConfirmationPop
        open={confirmPopup}
        handleClose={() => setConfirmPopup(false)}
        handleConfirm={handleConfirmLogout}
        description={"Are you sure you want to logout ?"}
      />
      <CreatePop open={createPopup} handleClose={() => setCreatePopup(false)} />
    </Box>
  );
}

export default Sidebar;
