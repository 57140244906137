import { Box, Dialog, Typography, Button } from "@mui/material";
import "./popup.css";

function ConfirmationPop(props) {
  const {
    open,
    handleClose,
    handleConfirm,
    description
  } = props;

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirm-pop"
      >
        <Box textAlign={"center"} >
          <Typography className="reminder-header">
            {description}
          </Typography>
          <Box mt={3}>
            <Button className="outlined-btn" onClick={handleClose} style={{marginRight:"20px"}}>
                No
            </Button>
            <Button className="contained-btn" onClick={handleConfirm}>
                yes
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ConfirmationPop;
