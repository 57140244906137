import React, { useState } from "react";

const Pagination = ({ total, currentPage, onPageChange }) => {
  return (
    <div style={styles.pagination}>
      <button
        style={styles.button}
        onClick={() => onPageChange(currentPage - 1, total)}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      <span style={styles.pageInfo}>
        {currentPage} / {total}
      </span>
      <button
        style={styles.button}
        onClick={() => onPageChange(currentPage + 1, total)}
        disabled={currentPage === total}
      >
        &gt;
      </button>
    </div>
  );
};

const styles = {
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0",
  },
  button: {
    border: "none",
    backgroundColor: "transparent",
    color: "#1976d2",
    fontSize: "15px",
    cursor: "pointer",
    padding: "5px 10px",
  },
  pageInfo: {
    margin: "0 0px",
    fontSize: "13px",
    color: "#1976d2",
  },
};

export default Pagination;
