import React, { useEffect, useState } from "react";
import classes from "./email.module.css";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { splitFirstLetter } from "../../utils/helper";
import { MdDelete } from "react-icons/md";
import ConfirmationPop from "../../popups/ConfirmationPop";
import { useDispatch } from "react-redux";
import { MdAddCircle } from "react-icons/md";
import { deleteConversation } from "../../api/Api";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import CreatePop from "../popup/CreatePop";

const EmailMessageSubSideBar = ({ emailsList, setSelectedEmail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createPopup, setCreatePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle between true and false
  };

  const extractName = (input) => {
    const match = input.match(/"([^"]*)"/);
    return match ? match[1] : null;
  };

  return (
    <Box
      className={`${classes.sidebar} ${isOpen ? classes.open : classes.closed}`}
    >
      <Button className={`${classes.toggleButton} ${classes.toggleButton2}`} onClick={toggleSidebar}>
        {isOpen ? <FaChevronCircleLeft /> : <FaChevronCircleRight />}
      </Button>
      <div className={classes.menuItems}>
        <ul>
          {emailsList &&
            emailsList.length > 0 &&
            emailsList.map((item, index) => {
              const name = extractName(item.from);
              return (
                <li>
                  <Box
                    className={`${classes.sidebar_logo}`}
                    key={item._id}
                    onClick={() => setSelectedEmail(item)}
                  >
                    {item?.profile_pic ? (
                      <Avatar
                        alt={item?.name}
                        src={item?.profile_pic.toString()}
                      />
                    ) : (
                      <Avatar
                        className={`${
                          window.location.pathname?.includes(item._id)
                            ? classes.SelectedAvatar
                            : classes.unSelectedAvatar
                        }`}
                      >
                        {name && splitFirstLetter(name)}
                      </Avatar>
                    )}
                    <div>
                      <Typography
                        textTransform={"capitalize"}
                        className={classes.userNameText}
                      >
                        {name}
                      </Typography>
                      <Typography className={classes.userEmailText}>
                        {item?.email}
                      </Typography>
                    </div>
                    {/* <Button
                    className={classes.deleteBinIcon}
                    onClick={(e) => handleDeleteClick(e, item)}
                  >
                    <MdDelete />
                  </Button> */}
                  </Box>
                </li>
              );
            })}
        </ul>
      </div>
      {/* <ConfirmationPop
      open={confirmationPopup}
      handleClose={() => setConfirmationPopup(false)}
      handleConfirm={handleDeleteConversation}
      description={`Are you Want to Delete Conversation "${
        selectedItem?.conversationInfo?.name.split("-")[1]
      }"`}
    /> */}
      <CreatePop open={createPopup} handleClose={() => setCreatePopup(false)} />
    </Box>
  );
};

export default EmailMessageSubSideBar;
