import { createSlice } from "@reduxjs/toolkit";

export const categoryReducer = createSlice({
  name: "category",
  initialState: {
    categoryList: [],
    subCategoryList: [],
  },
  reducers: {
    setCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    setSubCategoryList: (state, action) => {
      state.subCategoryList = action.payload;
    },
  },
});

export const { setCategoryList, setSubCategoryList } = categoryReducer.actions;
export default categoryReducer.reducer;
