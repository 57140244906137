import dayjs from "dayjs";

export const splitFirstLetter = (name) => {
  let new_name = name
    ?.split(" ")
    .map((val) => val?.at(0)?.toUpperCase())
    .join("");
  return new_name;
};

export const formatDuration = (dateString) => {
  const date = dayjs(dateString);
  const today = dayjs().startOf('day');
  const yesterday = dayjs().subtract(1, 'days').startOf('day');

  if (date.isSame(today, 'd')) {
    return 'Today';
  } else if (date.isSame(yesterday, 'd')) {
    return 'Yesterday';
  } else {
    return date.format('DD-MMM-YYYY');
  }
};
