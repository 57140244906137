import React, { useRef, useState } from "react";
import classes from "./user.module.css";
import { Box, Container } from "@mui/material";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { errorState, successState } from "../../redux/reducers/UserReducer";
import { useDispatch } from "react-redux";
import { RegenerateMessage, sendFeedback } from "../../api/Api";
import Messages from "./Messages";

const MessageContainer = ({
  userMsgs,
  handleScroll,
  formatDuration,
  initialsMsgs,
  setInitialMsgs,
  setIsPaginated,
  handleConversationInfo,
  setValue,
  endOfMessagesRef,
  containerRef,
}) => {
  const dispatch = useDispatch();

  function handleFileClick(infos) {
    let url = `https://api.mygenio.apps.org.in/api/${infos?.file_url}`;
    window.open(url, "_blank", "noopener,noreferrer");
  }

  return (
    <div>
      <Box
        className={classes.chat_part}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <Container>
          {Array.isArray(userMsgs) &&
            userMsgs.length > 0 &&
            userMsgs?.map((datas, i) => {
              let isJson = false;
              let parsedMessage;

              try {
                parsedMessage = JSON.parse(datas?.message);
                isJson = Array.isArray(parsedMessage);
              } catch (error) {
                isJson = false;
              }
              return (
                <div key={i}>
                  {(i === 0 ||
                    !dayjs(datas.createdAt).isSame(
                      userMsgs[i - 1].createdAt,
                      "day"
                    )) && (
                    <Box textAlign={"center"} py={2}>
                      <span className={classes.duration}>
                        {formatDuration(datas.createdAt)}
                      </span>
                    </Box>
                  )}
                  <Messages
                    datas={datas}
                    index={i}
                    isJson={isJson}
                    parsedMessage={parsedMessage}
                    initialsMsgs={initialsMsgs}
                    setInitialMsgs={setInitialMsgs}
                    setIsPaginated={setIsPaginated}
                    handleConversationInfo={handleConversationInfo}
                    setValue={setValue}
                  />
                </div>
              );
            })}
          <div ref={endOfMessagesRef} />
        </Container>
      </Box>
    </div>
  );
};

export default MessageContainer;
