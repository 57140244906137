import React, { useState, useEffect } from "react";
import { Tooltip, Button } from "@mui/material";
import { SiAudiomack } from "react-icons/si";
import { FaStop } from "react-icons/fa";
import { marked } from "marked";

function TextToSpeech({ message }) {
  const [text, setText] = useState("");
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);

  useEffect(() => {
    if (message && message !== "") {
      const convertMarkdownToPlainText = (markdown) => {
        // Use marked to convert Markdown to HTML first
        const html = marked(markdown);

        // Create a temporary element to strip out the HTML tags
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;

        // Get the plain text content
        return tempElement.textContent || tempElement.innerText || "";
      };

      // Convert the dynamic message from Markdown to plain text
      const convertedText = convertMarkdownToPlainText(message);
      setText(convertedText);
    }
  }, [message]);

  useEffect(() => {
    const synth = window.speechSynthesis;

    const updateVoices = () => {
      const availableVoices = synth.getVoices();
      setVoices(availableVoices);

      const femaleVoice = availableVoices.find(
        (voice) => voice.gender === "female" || voice.name.includes("Female")
      );
      setSelectedVoice(femaleVoice || availableVoices[0]);
    };

    updateVoices();
    synth.onvoiceschanged = updateVoices;

    return () => {
      synth.cancel(); // Stop any ongoing speech when the component unmounts
    };
  }, []);

  const handleSpeak = () => {
    if (selectedVoice) {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = selectedVoice;
      synth.speak(utterance);
      setIsSpeaking(true);

      utterance.onend = () => {
        setIsSpeaking(false);
      };
    }
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    setIsSpeaking(false);
  };

  return (
    <div>
      {isSpeaking ? (
        <Tooltip title="Stop" arrow>
          <Button onClick={handleStop}>
            <FaStop />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Read Aloud" arrow>
          <Button onClick={handleSpeak}>
            <SiAudiomack />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

export default TextToSpeech;
