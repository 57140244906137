// MessageInput.js
import { Box, TextField, InputAdornment, Tooltip } from "@mui/material";
import { EmojiIcon, SendIcon, UploadIcon } from "../../assets/icons/icons";

const MessageInput = ({
  value,
  setValue,
  showPicker,
  setShowPicker,
  handleImage,
  handleSendMsg,
  handleKeyPress,
}) => {
  return (
    <Box>
      <TextField
        fullWidth
        placeholder="Type a message"
        autoComplete="off"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: "22px" }}>
              <Box onClick={() => setShowPicker(!showPicker)}>
                <EmojiIcon />
              </Box>
              <Tooltip title="Upload image / audio" placement="top" arrow>
                <Box ml={1}>
                  <input
                    type="file"
                    onChange={handleImage}
                    hidden
                    multiple
                    accept="image/png, image/jpeg, image/jpg, audio/*"
                  />
                  <label>
                    <UploadIcon />
                  </label>
                </Box>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" onClick={handleSendMsg}>
              <SendIcon />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyPress}
      />
    </Box>
  );
};

export default MessageInput;
