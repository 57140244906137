import { createSlice } from "@reduxjs/toolkit";

export const userReducer = createSlice({
  name: "user",
  initialState: {
    sidebarToggle: false,
    errorAlert: null,
    successAlert: null,
    convoInfo: null,
  },
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebarToggle = action.payload;
    },
    errorState: (state, action) => {
      state.errorAlert = action.payload;
    },
    successState: (state, action) => {
      state.successAlert = action.payload;
    },
    updateConversationInfo: (state, action) => {
      state.convoInfo = action.payload;
    },
  },
});

export const {
  toggleSidebar,
  errorState,
  successState,
  updateConversationInfo,
} = userReducer.actions;
export default userReducer.reducer;
