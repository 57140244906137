import axios from "axios";
import {
  setCategoryList,
  setSubCategoryList,
} from "../redux/reducers/categoryReducer";

const handleGetToken = () => {
  const _token = JSON.parse(localStorage.getItem("userToken"));
  if (_token) {
    return _token?.accessToken;
  } else {
    return null;
  }
};

export const registerAction = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/register-user`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const loginAction = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getConversationInfo = (body) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/conversation/get-conversations`,
      {
        headers: {
          Authorization: `Bearer ${handleGetToken()}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getConversationMsgs = (convo_id, page) => {
  console.log(convo_id, page);

  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/conversation/messages/${convo_id}?limit=${page}&page=1`,
      {
        headers: {
          Authorization: `Bearer ${handleGetToken()}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const deleteConversation = (convo_id, page) => {
  console.log(convo_id, page);

  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}/api/conversation/delete/${convo_id}`,
      {
        headers: {
          Authorization: `Bearer ${handleGetToken()}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const sendFeedback = (convo_id, body) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/message/responce-feedback/${convo_id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${handleGetToken()}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const sendMessage = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/message/save-message`, body, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getGoogleAuthnticationToken = (obj) => {
  console.log("Backend URL", `${process.env.REACT_APP_API_URL}/api/auth/email-verification${obj}`);
  
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/auth/email-verification${obj}`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const RegenerateMessage = (id) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/api/message/regenerate/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${handleGetToken()}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const forgotPassword = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, body, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const verifyOtpPassword = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/auth/verify-otp`, body, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const resetNewPassword = (body, token) => {
  return axios
    .patch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const uploadFile = (body) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/api/message/upload-file`, body, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMyReminders = (user_id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/user/events/${user_id}`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMyEmails = () => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/email/email-configs`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMyEmailMessages = (id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/email/config/${id}`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getMyNotifications = (user_id) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/notification/${user_id}`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getCategoriesList = () => async (dispatch) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/category`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      dispatch(setCategoryList(res?.data?.data));
      return;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getSubCategoriesList = (id) => async (dispatch) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/api/sub-category/${id}`, {
      headers: {
        Authorization: `Bearer ${handleGetToken()}`,
      },
    })
    .then((res) => {
      dispatch(setSubCategoryList(res?.data?.data));
      return;
    })
    .catch((err) => {
      return err.response;
    });
};

export const createNewConversation =
  (userEntry, setSuccessModal) => async (dispatch) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/conversation/create-conversation`,
        userEntry,
        {
          headers: {
            Authorization: `Bearer ${handleGetToken()}`,
          },
        }
      )
      .then(async (res) => {
        await getConversationInfo();
        setSuccessModal(true);
      })
      .catch((err) => {
        return err.response;
      });
  };
