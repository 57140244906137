import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/UserReducer";
import categoryReducer from "./reducers/categoryReducer";

export const MyStore = configureStore({
  reducer: {
    user: userReducer,
    category: categoryReducer,
  },
  devTools: true,
});
