import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
//   import "./css/LoginStyles.css";
import * as Components from "./LoginStyledComponent.js";
import { Link, useNavigate } from "react-router-dom";
import classes from "./auth.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { forgotPassword, loginAction, registerAction, resetNewPassword, verifyOtpPassword } from "../../api/Api.js";
import { useDispatch, useSelector } from "react-redux";
import { errorState, successState } from "../../redux/reducers/UserReducer.js";
import OtpInput from "react-otp-input";

function ForgotPassword() {
  const [signIn, toggle] = useState(true);
  const [passWordOne, setPassWordOne] = useState(false);
  const [passWordtwo, setPassWordtwo] = useState(false);
  const [page, setPage] = useState(1);
  const [otp, setOtp] = useState("");
  const [myOtp, setMyOtp] = useState("");
  const [userName, setUserName] = useState("");
  const [passwords, setPasswords] = useState({
    new:"",
    confirmNew:""
  });
  const [passwordErr, setPasswordErr] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorAlert, successAlert } = useSelector((state) => state.user);


  const handleVerifyOtp = async (e) => {
    let body = { username:userName,otp};

    let result = await verifyOtpPassword(body);
    
    if (result?.status === 201 || result?.status === 200) {
      setMyOtp(result?.data?.data);
      setPage(3);
    } else {
      dispatch(errorState(result?.data?.message));
    }
  };

  const handleResetPassword = async (e) => {
    if (passwords.new != passwords.confirmNew) {
      setPasswordErr(true);
    } else {
      let body = { username:userName,newPassword:passwords.new,confirmPassword:passwords.confirmNew};

      let result = await resetNewPassword(body,myOtp);
      
      if (result?.status === 201 || result?.status === 200) {
       
      } else {
        dispatch(errorState(result?.data?.message));
      }
    }

  };

  const handleSendOtp = async (e) => {
    
    let body = { username:userName};

    let result = await forgotPassword(body);
    
    if (result?.status === 201 || result?.status === 200) {
      setMyOtp(result?.data?.data?.otp?.otp)
      setPage(2);
    } else {
      dispatch(errorState(result?.data?.message));
    }
  };
  
  return (
    <div data-aos="fade-down" data-aos-duration="700" className="scale-down-center" >
      <Box display={{ xs: "none", sm: "block", md: "block" }}>
        <Components.Container>
          <Components.SignUpContainer
            signingIn={signIn}
          ></Components.SignUpContainer>
          <Components.SignInContainer signingIn={signIn}>
            <Components.Form>
              {page === 1 && (
                <>
                  {" "}
                  <Components.Title>Reset Password</Components.Title>
                  <ValidatorForm useref="form" onSubmit={handleSendOtp}>
                    <TextValidator
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      id="emailInput"
                      placeholder="Email or phone"
                      className={`my-field`}
                      name={"userName"}
                      value={userName}
                      size="normal"
                      onChange={(e)=>setUserName(e.target.value)}
                      style={{ margin: "12px 0" }}
                      validators={["required"]}
                      errorMessages={["Email or phone is required"]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FaRegUser className={classes.input_icons} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box mt={2} textAlign={"center"}>
                      <Button className={classes.register_btn} type="submit">
                        Send Otp
                      </Button>
                    </Box>
                    <Typography className={classes.already_acc}>
                 <span onClick={() => navigate("/")}>Back to login</span>
               </Typography>
                  </ValidatorForm>
                </>
              )}
              {page === 2 && (
                <>
                  {" "}
                  <Components.Title>Verify OTP</Components.Title>
                  <Typography marginBottom={"31px"} fontSize={"13px"}>
                    We have sent a <b>4 difit OTP</b>. Please enter to continue. <br />{myOtp}
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    containerStyle={classes.otpInputContainer}
                    inputStyle={classes.otpInput}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  <Box mt={2} textAlign={"center"}>
                    <Button className={classes.register_btn} onClick={handleVerifyOtp}>
                      Verify Otp
                    </Button>
                    <Button
                      className={classes.cancel_btn}
                      type="submit"
                      style={{ marginTop: "15px" }}
                      onClick={() => setPage(1)}
                    >
                      back
                    </Button>
                  </Box>
                </>
              )}
              {page === 3 && (
                <>
                  {" "}
                  <Components.Title>New Password</Components.Title>
                  <ValidatorForm useref="form" onSubmit={handleResetPassword}>
                    <TextValidator
                      type={passWordOne ? "text":"password"}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      id="emailInput"
                      placeholder="New Password"
                      className={`my-field`}
                      name={"password"}
                      value={passwords.new}
                      size="normal"
                      onChange={(e)=>setPasswords({...passwords,new:e.target.value})}
                      style={{ margin: "12px 0" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiLockPasswordFill
                              className={classes.input_icons}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start" onClick={()=>setPassWordOne(!passWordOne)} >
                           {passWordOne ? <IoEyeOutline className={classes.eye_icons} /> : 
                            <FaRegEyeSlash  className={classes.eye_icons} />}
                          </InputAdornment>
                        ),
                      }}
                      validators={["required","matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"]} 
                      errorMessages={["Password is required","Please enter strong password"]}
                    />
                    <TextField
                      type={passWordtwo ? "text":"password"}
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      id="emailInput"
                      placeholder="Confirm New Password"
                      className={`my-field`}
                      name={"password"}
                      value={passwords.confirmNew}
                      size="normal"
                      onChange={(e)=> {
                        setPasswordErr(false)
                        setPasswords({...passwords,confirmNew:e.target.value})}}
                      style={{ margin: "12px 0" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiLockPasswordFill
                              className={classes.input_icons}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start" onClick={()=>setPassWordtwo(!passWordtwo)} >
                           {passWordtwo ? <IoEyeOutline className={classes.eye_icons} /> : 
                            <FaRegEyeSlash  className={classes.eye_icons} />}
                          </InputAdornment>
                        ),
                      }}
                      helperText={ passwordErr &&  "Password doesn't match"}
                      error={passwordErr}
                    />

                    <Box mt={2} textAlign={"center"}>
                      <Button className={classes.register_btn} type="submit">
                        Reset Password
                      </Button>
                    </Box>
                    </ValidatorForm>
                </>
              )}
            </Components.Form>
          </Components.SignInContainer>
          <Components.OverlayContainer signingIn={signIn}>
            <Components.Overlay signingIn={signIn}>
              <Components.LeftOverlayPanel
                signingIn={signIn}
              ></Components.LeftOverlayPanel>
              <Components.RightOverlayPanel signingIn={signIn}>
                <Components.Title>Forgot Your Password?</Components.Title>
                <Components.Paragraph>
                  Don't worry! We've got you covered.
                </Components.Paragraph>
              </Components.RightOverlayPanel>
            </Components.Overlay>
          </Components.OverlayContainer>
        </Components.Container>
      </Box>
      {/* <Box display={{ xs: "block", sm: "none", md: "none" }}>

        <Components.Form>
          <Components.Title>Reset Password</Components.Title>
          <ValidatorForm useref="form" onSubmit={handleSendOtp}>
            <TextValidator
              variant="outlined"
              autoComplete="off"
              fullWidth
              id="emailInput"
              placeholder="Email or phone"
              className={`my-field`}
              name={"username"}
              value={signInInputs.username}
              size="normal"
              onChange={handleInputs}
              style={{ margin: "12px 0" }}
              validators={["required"]}
              errorMessages={["Email or phone is required"]}
              // inputRef={input1}
              // onKeyPress={(e) => handleKeyPress(e, input2)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaRegUser className={classes.input_icons} />
                  </InputAdornment>
                ),
              }}
            />

            <TextValidator
              type={passwordView ? "text" : "password"}
              variant="outlined"
              autoComplete="off"
              fullWidth
              id="emailInput"
              placeholder="Password"
              className={`my-field`}
              name={"password"}
              value={signInInputs.password}
              size="normal"
              onChange={handleInputs}
              style={{ margin: "12px 0" }}
              // inputRef={input2}
              // onKeyPress={(e) => handleKeyPress(e, input3)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiLockPasswordFill className={classes.input_icons} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setPasswordView(!passwordView)}
                  >
                    {passwordView ? (
                      <IoEyeOutline className={classes.eye_icons} />
                    ) : (
                      <FaRegEyeSlash className={classes.eye_icons} />
                    )}
                  </InputAdornment>
                ),
              }}
              validators={["required"]}
              errorMessages={["Password is required"]}
            />
            <Typography className={classes.forget_password}>
              <span> Forgot Password ?</span>
            </Typography>

            <Box mt={2} textAlign={"center"}>
              <Button className={classes.register_btn} type="submit">
                Login
              </Button>
            </Box>
            <Typography className={classes.already_acc} mt={2}>
              Create an Account ?{" "}
              <span onClick={() => toggle(false)}>Sign Up</span>
            </Typography>
          </ValidatorForm>
        </Components.Form>
      </Box> */}
    </div>
  );
}

export default ForgotPassword;
